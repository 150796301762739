import Lottie from 'react-lottie';
import clickAndHold from '../../assets/pass/clickAndHold.png';
import attention from '../../assets/pass/attention.json';

import { boxTriggered } from './passFunctions.js';
import { useRef, useState, useEffect} from 'react';

import exclusiveEventsTitle from '../../assets/pass/exclusiveEventsTitle.png';
import exclusiveEventsLine from '../../assets/pass/exclusiveEventsLine.png';
import EventsImage from '../../assets/pass/clickAndHold/Events.png';

export function ExclusiveEvents({isClickAndHold ,setIsClickAndHold}) {
  const holdThreshold = 100;
  const holdTimerRef = useRef(null);

  const [brightness, setBrightness] = useState(1);
  
  const handleMouseDown = (divName) => {
    holdTimerRef.current = setTimeout(() => {
      let currentBrightness = 1;
      const interval = setInterval(() => {
        currentBrightness += 0.1;
        setBrightness(currentBrightness);

        if (currentBrightness >= 1.5) {
          setIsClickAndHold(true);
          boxTriggered(true, divName, "attentionExclusiveEvents");
          clearInterval(interval);
        }
      }, 50);
    }, holdThreshold);
  };
  const handleMouseUp = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionExclusiveEvents");
    setBrightness(1);
  };
  const handleMouseLeave = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionExclusiveEvents");
    setBrightness(1);
  };

  useEffect(() => {
    // Apply brightness filter to #tvBox
    const nftBoxElement = document.getElementById('clickHoldExclusiveEvents');
    nftBoxElement.style.filter = `brightness(${brightness})`;

    return () => {
        nftBoxElement.style.filter = 'none';
    };
  }, [brightness]);

  return (
    <>
      <div id='exclusiveEventsVisibility'>
        <div 
          id='exclusiveEventsBox'
          onMouseDown={()=>handleMouseDown("exclusiveEventsDiv")}
          onMouseUp={()=>handleMouseUp("exclusiveEventsDiv")}
          onMouseLeave={() => handleMouseLeave("exclusiveEventsDiv")}
        ></div>
        <img id='clickHoldExclusiveEvents' alt='clickHoldExclusiveEvents' src={clickAndHold}></img>
      </div>
      <div id='attentionExclusiveEvents'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: attention,
          }}
          height={"10vw"}
          width={"7vw"}
        />
      </div>

      <div id='exclusiveEventsDiv'>
        <img src={exclusiveEventsTitle} alt='exclusiveEventsTitle' className='accessAcademyTitle'></img>
        <img src={exclusiveEventsLine} alt='exclusiveEventsLine' className='exclusiveEventsLine'></img>
        <p className='accessAcademyPara'>
        Pass holders gain access to exclusive
        IRL events, joining a global community at
        premier Chimba Studios gatherings around the world.
        </p>
        <img src={EventsImage} alt='accessAcademyPH' className='accessAcademyPH'></img>
      </div>

    </>
  );
}