import React, { useEffect, useState } from "react";
import { useLocation, useRoute } from "wouter";
import { NavBar } from "../NavBar";
import "./Css/MainInfo.css";

// Import other images
import ClickLine from "../../assets/mainInfo/ClickLine.png";
import BottomLine from "../../assets/mainInfo/BottomLine.webp";
import BottomRightLine from "../../assets/mainInfo/BottomRightLine.png";
import X from "../../assets/mainInfo/X.png";
import Discord from "../../assets/mainInfo/Discord.png";
import YT from "../../assets/mainInfo/YT.png";
import IG from "../../assets/mainInfo/IG.png";
import TIKTOK from "../../assets/mainInfo/TIKTOK.png";
import chimbaLogoGrey from "../../assets/mainInfo/chimbaLogoGrey.png";
import rightSideLine from "../../assets/mainInfo/rightSideLine.png";
import leftSideLine from "../../assets/mainInfo/leftSideLine.png";
import rotateIcon from "../../assets/mainInfo/rotateIcon.png";

export function MainInfo() {
  const [, params] = useRoute("/page/:id");
  const [, setLocation] = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
      setIsLargeScreen(window.innerWidth >= 1580);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const goTo = (weblink) => {
    window.open(weblink, "_blank");
  };

  return (
    <>
      <NavBar route="home" />
      <div className="container">
        <img
          src={ClickLine}
          alt="ClickLine"
          className={!isSmallScreen ? "ClickLine" : "hidden"}
        />

        <img
          src={BottomLine}
          alt="BottomLine"
          className={!isSmallScreen ? "BottomLine" : "hidden"}
        />
        <img
          src={BottomRightLine}
          alt="BottomRightLine"
          className={!isSmallScreen ? "BottomRightLine" : "hidden"}
        />
        <img
          src={X}
          alt="X"
          className={!isSmallScreen ? "X" : "hidden"}
          onClick={() => {
            goTo("https://x.com/Chimba_Studios");
          }}
        />
        <img
          src={Discord}
          alt="Discord"
          className={!isSmallScreen ? "Discord" : "hidden"}
          onClick={() => {
            goTo("https://discord.com/invite/chimbastudios");
          }}
        />
        <img
          src={YT}
          alt="YT"
          className={!isSmallScreen ? "YT" : "hidden"}
          onClick={() => {
            goTo("https://www.youtube.com/@ChimbaStudios");
          }}
        />
        <img src={IG} alt="IG" className={!isSmallScreen ? "IG" : "hidden"} />
        <img
          src={TIKTOK}
          alt="TIKTOK"
          className={!isSmallScreen ? "TIKTOK" : "hidden"}
        />

        <a
          className={`link-text ${params ? "active" : ""} ${
            isSmallScreen ? "small-screen" : ""
          }`}
          href="#"
          onClick={() => setLocation("/")}
        >
          {params ? "CLICK ME TO GO BACK HOME" : "DOUBLE CLICK CARDS TO ENTER"}
        </a>

        {isLargeScreen && !params && (
          <>
            <img src={leftSideLine} className="leftSideLine" />
            <img src={rightSideLine} className="rightSideLine" />
            <img src={chimbaLogoGrey} className="chimbaLogoGreyHome" />
            <img src={rotateIcon} className="rotateIcon" />
          </>
        )}
        {!isLargeScreen && !params && (
          <>
            <img src={chimbaLogoGrey} className="chimbaLogoGreyHomeMobile" />
            <img src={rotateIcon} className="rotateIconMobile" />
          </>
        )}
      </div>
    </>
  );
}
