import Lottie from 'react-lottie';
import clickAndHold from '../../assets/pass/clickAndHold.png';
import attention from '../../assets/pass/attention.json';

import { boxTriggered } from './passFunctions.js';
import { useRef, useState, useEffect} from 'react';

import earlyTitle from '../../assets/pass/earlyTitle.png';
import earlyLine from '../../assets/pass/earlyLine.png'
import EarlyAccessImg from '../../assets/pass/clickAndHold/EarlyAccess.png';

export function EarlyAccess({isClickAndHold ,setIsClickAndHold}) {
  const holdThreshold = 100;
  const holdTimerRef = useRef(null);

  const [brightness, setBrightness] = useState(1);
  
  const handleMouseDown = (divName) => {
    holdTimerRef.current = setTimeout(() => {
      let currentBrightness = 1;
      const interval = setInterval(() => {
        currentBrightness += 0.1;
        setBrightness(currentBrightness);

        if (currentBrightness >= 1.5) {
          setIsClickAndHold(true);
          boxTriggered(true, divName, "attentionEarly");
          clearInterval(interval);
        }
      }, 50);
    }, holdThreshold);
  };
  const handleMouseUp = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionEarly");
    setBrightness(1);
  };
  const handleMouseLeave = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionEarly");
    setBrightness(1);
  };

  useEffect(() => {
    // Apply brightness filter to #tvBox
    const chestBoxElement = document.getElementById('clickHoldEarly');
    chestBoxElement.style.filter = `brightness(${brightness})`;

    return () => {
      chestBoxElement.style.filter = 'none';
    };
  }, [brightness]);

  return (
    <>
      <div id='earlyVisibility'>
        <div 
          id='earlyBox'
          onMouseDown={()=>handleMouseDown("earlyDiv")}
          onMouseUp={()=>handleMouseUp("earlyDiv")}
          onMouseLeave={() => handleMouseLeave("earlyDiv")}
        ></div>
        <img id='clickHoldEarly' alt='clickHoldEarly' src={clickAndHold}></img>
      </div>
      <div id='attentionEarly'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: attention,
          }}
          height={"10vw"}
          width={"7vw"}
        />
      </div>

      <div id='earlyDiv'>
        <img src={earlyTitle} alt='earlyTitle' className='accessAcademyTitle'></img>
        <img src={earlyLine} alt='earlyLine' className='earlyLine'></img>
        <p className='accessAcademyPara'>
        Obtain early access to alpha and
        beta game tests, allowing members to stay ahead
        and unlock exclusive in-game assets first.
        </p>
        <img src={EarlyAccessImg} alt='accessAcademyPH' className='accessAcademyPH'></img>

      </div>
    </>
  );
}