import "./css/character.css";

import background from "../../../assets/cosmiclabs/duck.png";
import lines2 from "../../../assets/fullScreenLines/lines5.png";

export function Characters() {
  return (
    <>
      <img src={background} alt="background" className="background3Cosmic" />
      <img src={lines2} alt="lines2" className="lines2Cosmic" />
      <h2 className="mainHeaderCosmicChar">Art-Driven NFTs</h2>
      <div className="group-containerCosmicChar">
        <div className="rectangle rectangle-1CosmicChar"></div>
        <div className="rectangle rectangle-2CosmicChar"></div>
      </div>

      <div className="characterParaDivDucks">
        <p className="historyPara">
          <span className="historyTitleCursed whiteTitle">MISSION</span>
          <br />
          Cosmic Genesis is dedicated to fostering an art-driven community,<br />
          celebrating creativity and exceptional design. With its collection of<br />
          1,000 Galactic Ducks, the project aims to inspire and unite<br />
          individuals who value the power of fun and unique art.
        </p>
      </div>
    </>
  );
}
