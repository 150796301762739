import Lottie from 'react-lottie';
import clickAndHold from '../../assets/pass/clickAndHold.png';
import attention from '../../assets/pass/attention.json';

import { boxTriggered } from './passFunctions.js';
import { useRef, useState, useEffect} from 'react';

import behindTitle from '../../assets/pass/behindTitle.png';
import behindLine from '../../assets/pass/behindLine.png'
import Behind1 from '../../assets/pass/clickAndHold/Behind1.png';
import Behind2 from '../../assets/pass/clickAndHold/Behind2.png';
import Behind3 from '../../assets/pass/clickAndHold/Behind3.png';
export function Behind({isClickAndHold ,setIsClickAndHold}) {
  const holdThreshold = 100;
  const holdTimerRef = useRef(null);

  const [brightness, setBrightness] = useState(1);
  
  const handleMouseDown = (divName) => {
    holdTimerRef.current = setTimeout(() => {
      let currentBrightness = 1;
      const interval = setInterval(() => {
        currentBrightness += 0.1;
        setBrightness(currentBrightness);

        if (currentBrightness >= 1.5) {
          setIsClickAndHold(true);
          boxTriggered(true, divName, "attentionBehind");
          clearInterval(interval);
        }
      }, 50);
    }, holdThreshold);
  };
  const handleMouseUp = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionBehind");
    setBrightness(1);
  };
  const handleMouseLeave = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionBehind");
    setBrightness(1);
  };

  useEffect(() => {
    // Apply brightness filter to #tvBox
    const chestBoxElement = document.getElementById('clickHoldBehind');
    chestBoxElement.style.filter = `brightness(${brightness})`;

    return () => {
      chestBoxElement.style.filter = 'none';
    };
  }, [brightness]);

  return (
    <>
      <div id='behindVisibility'>
        <div 
          id='behindBox'
          onMouseDown={()=>handleMouseDown("behindDiv")}
          onMouseUp={()=>handleMouseUp("behindDiv")}
          onMouseLeave={() => handleMouseLeave("behindDiv")}
        ></div>
        <img id='clickHoldBehind' alt='clickHoldBehind' src={clickAndHold}></img>
      </div>
      <div id='attentionBehind'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: attention,
          }}
          height={"10vw"}
          width={"7vw"}
        />
      </div>

      <div id='behindDiv'>
        <img src={behindTitle} alt='behindTitle' className='accessAcademyTitle'></img>
        <img src={behindLine} alt='behindLine' className='behindLine'></img>
        <p className='accessAcademyPara'>
          Gain exclusive insight into the development
          of Chimba Studios' IPs and discover upcoming
          releases before anyone else.
        </p>
        <img src={Behind1} alt='accessAcademyPH' className='behindScenes'></img>
        <img src={Behind2} alt='accessAcademyPH' className='behindScenes2'></img>
        <img src={Behind3} alt='accessAcademyPH' className='behindScenes3'></img>
      </div>
    </>
  );
}