import React, { useEffect } from 'react';
import { NavBar } from '../NavBar';

import './Css/foundation.css'
//Section one
import line1 from '../../assets/foundation/line1.png'
import line2 from '../../assets/foundation/line2.png'
import line3 from '../../assets/foundation/line3.png'
import scrollToNav from '../../assets/foundation/scrollToNav.png'
import mouse from '../../assets/foundation/mouse.png'
import foundationHeader from '../../assets/foundation/foundationHeader.png'
import logo from '../../assets/foundation/chimbaPink.png'
import pinkBlock from '../../assets/foundation/pinkBlock.png'
import pinkTextBlock from '../../assets/foundation/pinkTextBlock.png'
import stayUpdated from '../../assets/foundation/stayUpdated.png'

//Section two
import chimbaLogoColor from '../../assets/foundation/chimbaLogoColor.png'
import line4 from '../../assets/foundation/line4.png'
import line5 from '../../assets/foundation/line5.png'
import line6 from '../../assets/foundation/line6.png'
import lineAccent from '../../assets/foundation/lineAccent.png'
import firstHeader from '../../assets/foundation/inclusive.webp'
import secondHeader from '../../assets/foundation/launchpad.webp'
import Footer from '../Footer';

export function Foundation() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
            <NavBar route="foundation"/>

            <img src={line1} alt="line1" className="line1Fo" />
            <img src={line2} alt="line2" className="line2F" />
            <img src={line3} alt="line3" className="line3F" />
            <img src={scrollToNav} alt="scrollToNav" className="scrollToNavF" />
            <img src={mouse} alt="mouse" className="mouseF" />
            <img src={foundationHeader} alt="foundationHeader" className="foundationHeader" />
            <img src={logo} alt="logo" className="chimbaPinkF" />
            <img src={pinkBlock} alt="pinkBlock" className="pinkBlock" />
            <img src={pinkTextBlock} alt="pinkTextBlock" className="pinkTextBlock" />
            <img src={stayUpdated} alt="stayUpdated" className="stayUpdated" onClick={()=>window.open("https://x.com/chimba_studios")} />
            <p className='foundationPara'>
                Chimba Foundation empowers communities with <br></br>
                &nbsp;&nbsp;&nbsp;tech donations, enabling access to the digital world.<br></br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We bridge gaps and nurture potential.
            </p>

            <img src={chimbaLogoColor} alt="chimbaLogoColor" className="chimbaLogoColor" />
            <img src={line4} alt="line4" className="line4F" />
            <img src={line5} alt="line5" className="line5F" />
            <img src={line6} alt="line6" className="line6F" />
            <img src={lineAccent} alt="lineAccent" className="lineAccent" />

            <img src={firstHeader} alt="firstHeader" className="firstHeaderF" />
            <p className='firstHPF'>
                Through scholarships and workshops, we offer a<br></br>
                launchpad for undiscovered talent in gaming, animation, <br></br>
                and crypto. Equality in education and opportunity.
            </p>

            <img src={secondHeader} alt="secondHeader" className="secondHeaderF" />
            <p className='secondHPF'>
                Our events and software grants create inclusive spaces for all. <br></br>
                Join us in leveraging gaming for impactful community initiatives <br></br>
                and social upliftment.
            </p>
            

            <Footer height={"94vw"}/>
        </>
    )
};