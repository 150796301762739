import React, { useEffect, useState } from "react";
import "./Css/academy.css";
import { NavBar } from "../NavBar";
import Footer from "../Footer";
import line1 from "../../assets/academy/line1.png";
import line2 from "../../assets/academy/line2.png";
import line3 from "../../assets/academy/line3.png";
import scrollToNav from "../../assets/academy/scrollToNav.png";
import mouse from "../../assets/academy/mouse.png";
import academyHeader from "../../assets/academy/academy.png";
//Second page
import line4 from "../../assets/academy/line4.png";
import line5 from "../../assets/academy/line5.png";
import chimbaLogo from "../../assets/academy/chimbaLogo.webp";

//secondPage headers
import elearingHeader from "../../assets/academy/headers/elearning.png";
import downloadsHeader from "../../assets/academy/headers/downloads.png";
import subscriptionHeader from "../../assets/academy/headers/subscription.png";
import tutoringHeader from "../../assets/academy/headers/tutoring.png";
import workHeader from "../../assets/academy/headers/work.png";

//secondPage tabs
import graduate from "../../assets/academy/buttons/graduate.png";
import graduateSelected from "../../assets/academy/buttons/graduateSelected.png";
import bell from "../../assets/academy/buttons/bell.png";
import bellSelected from "../../assets/academy/buttons/bellSelected.png";
import work from "../../assets/academy/buttons/work.png";
import workSelected from "../../assets/academy/buttons/workSelected.png";
import group from "../../assets/academy/buttons/group.png";
import groupSelected from "../../assets/academy/buttons/groupSelected.png";
import folder from "../../assets/academy/buttons/folder.png";
import folderSelected from "../../assets/academy/buttons/folderSelected.png";

//secondPage images
import learningImage from "../../assets/academy/images/learning.webp";
import downloadImage from "../../assets/academy/images/downloads.webp";
import tutoringImage from "../../assets/academy/images/tutoring.webp";
import subscriptionImage from "../../assets/academy/images/subscription.webp";
import workImage from "../../assets/academy/images/work.webp";

//lineBottom
import lineBottom from "../../assets/academy/lineBottom.png";

export function Academy() {
  const [selectedTab, setSelectedTab] = useState("graduate");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const getImageSource = (tab) => {
    switch (tab) {
      case "graduate":
        return selectedTab === "graduate" ? graduateSelected : graduate;
      case "bell":
        return selectedTab === "bell" ? bellSelected : bell;
      case "work":
        return selectedTab === "work" ? workSelected : work;
      case "group":
        return selectedTab === "group" ? groupSelected : group;
      case "folder":
        return selectedTab === "folder" ? folderSelected : folder;
      default:
        return "";
    }
  };
  const getTitle = (tab) => {
    switch (tab) {
      case "graduate":
        return "E-LEARNING";
      case "bell":
        return "SUBSCRIPTION";
      case "work":
        return "WORK WITH US";
      case "group":
        return "TUTORING";
      case "folder":
        return "DOWNLOADS";
      default:
        return "";
    }
  };
  
  const getTitleWidth = (tab) => {
    switch (tab) {
      case "graduate":
        return "19.1vw";
      case "bell":
        return "22.7vw";
      case "work":
        return "23.9vw";
      case "group":
        return "15.5vw";
      case "folder":
        return "19.9vw";
      default:
        return "19.1vw";
    }
  };
  const getDescription = (tab) => {
    switch (tab) {
      case "graduate":
        return (
          <>
            Begin a saga at Chimba Academy, where curiosity sparks and passion
            fuels the ascent from eager novice to esteemed maestro.
            <br />
            <br />
            Each course is a chapter in your story of mastering digital arts,
            coding, trading, and game creation.
            <br />
            <br />
            Here, tomorrow's innovators are forged in today's lessons.
          </>
        );
      case "bell":
        return (
          <>
            Dive into a world of endless learning with Chimba Studios'
            subscription model.
            <br />
            <br />
            Monthly subscribers gain full access to all our digital courses,
            while Chimba Pass holders enjoy lifetime access to a vast library of
            content.
            <br />
            <br />
            Transform your passion into expertise and join a community dedicated
            to digital excellence.
          </>
        );
      case "work":
        return (
          <>
            Begin at Chimba Academy, your gateway to mastering game development,
            digital arts, and blockchain.
            <br />
            <br />
            Excel in your craft, and you may join Chimba Studios, where
            visionaries unite to forge groundbreaking projects in gaming and
            animation.
            <br />
            <br />
            Here, your ambition meets our educational prowess, setting the stage
            for a future rich with innovation and creative triumphs.
          </>
        );
      case "group":
        return (
          <>
            Chimba Academy's 1-on-1 tutoring is a catalyst for your potential,
            offering personalized mentorship to deepen your expertise.
            <br />
            <br />
            Our dedicated tutors tailor each session to your unique goals,
            ensuring you not only meet but exceed your aspirations in the
            digital arts and gaming spheres.
            <br />
            <br />
            Forge ahead with confidence under the wing of industry experts.
          </>
        );
      case "folder":
        return (
          <>
            Step into Chimba Academy's vault of knowledge, where a wealth of
            resources awaits to elevate your learning.
            <br />
            <br />
            Download our expertly crafted guides, insightful tutorials, and
            robust educational materials.
            <br />
            <br />
            Empower your studies with assets designed for success, and let our
            resources be the wings that lift your expertise to new heights,
            forging your path to mastery.
          </>
        );
      default:
        return "";
    }
  };
  const getP2ImageSource = (tab) => {
    switch (tab) {
      case "graduate":
        return learningImage;
      case "bell":
        return subscriptionImage;
      case "work":
        return workImage;
      case "group":
        return tutoringImage;
      case "folder":
        return downloadImage;
      default:
        return "";
    }
  };
  const getDescriptionBottom = (tab) => {
    switch (tab) {
      case "graduate":
        return (
          <>
            "Education is the kindling of a flame, not the filling of a vessel."
          </>
        );
      case "bell":
        return (
          <>
            "Empower your creativity; your pass to a universe of learning
            awaits."
          </>
        );
      case "work":
        return <>"Where skills flourish, futures are forged."</>;
      case "group":
        return <>"He who is taught only by himself has a fool for a master."</>;
      case "folder":
        return <>"Knowledge is power. Information is liberating."</>;
      default:
        return "";
    }
  };

  return (
    <>
      <NavBar route="academy" />
      <div className="academy-container">
        <img src={line1} alt="line1" className="line1" />
        <img src={line2} alt="line2" className="line2" />
        <img src={line3} alt="line3" className="line3" />
        <img src={scrollToNav} alt="scrollToNav" className="scrollToNav" />
        <img src={mouse} alt="mouse" className="mouse" />
        <img
          src={academyHeader}
          alt="academyHeader"
          className="academyHeader"
        />
        <h1 className="p1Header">Digital Hub</h1>
        <p className="p1Para">
          Unleash creativity in gaming and digital arts. Explore Expert-led
          courses in design, programming, web3 and more. Start your journey in
          innovation, elevate your skills, and join a community of creators.
        </p>

        {/* second page */}
        <img src={line4} alt="line4" className="line4" />
        <img src={line5} alt="line5" className="line5" />
        <img src={chimbaLogo} alt="chimbaLogo" className="chimbaLogoAcademy" />

        <h2 className="storyHeaderAcademy">{getTitle(selectedTab)}</h2>
        <div className="group-containerAcademy">
          <div className="rectangle rectangle-1Acad" style={{width: getTitleWidth(selectedTab)}}></div>
          <div className="rectangle rectangle-2Acad" ></div>
        </div>
        <img
          src={getP2ImageSource(selectedTab)}
          alt={selectedTab}
          className="p2Image"
        />
        <p className="p2Para">{getDescription(selectedTab)}</p>

        <img
          src={getImageSource("graduate")}
          alt="tab1"
          className={`graduate ${
            selectedTab === "graduate" ? "selectedGroup" : ""
          }`}
          onClick={() => handleTabClick("graduate")}
        />
        <img
          src={getImageSource("bell")}
          alt="tab2"
          className={`bell ${selectedTab === "bell" ? "selectedGroup" : ""}`}
          onClick={() => handleTabClick("bell")}
        />
        <img
          src={getImageSource("work")}
          alt="tab3"
          className={`work ${selectedTab === "work" ? "selectedGroup" : ""}`}
          onClick={() => handleTabClick("work")}
        />
        <img
          src={getImageSource("group")}
          alt="tab4"
          className={`group ${selectedTab === "group" ? "selectedGroup" : ""}`}
          onClick={() => handleTabClick("group")}
        />
        <img
          src={getImageSource("folder")}
          alt="tab5"
          className={`folder ${
            selectedTab === "folder" ? "selectedGroup" : ""
          }`}
          onClick={() => handleTabClick("folder")}
        />

        <img src={lineBottom} alt="lineBottom" className="lineBottom" />
        <p className="p2ParaBottom">{getDescriptionBottom(selectedTab)}</p>
      </div>

      <Footer height={"111vw"} />
    </>
  );
}
