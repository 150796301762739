import React, { useEffect, useRef } from 'react';
import { NavBar } from '../../NavBar';

import './Css/cyberskies.css'

import line1 from '../../../assets/fullScreenLines/lines1.png'
import { History } from './History';
import { Character } from './Character';
import Footer from '../../Footer';
import { Ships } from './Ships';
import { Rewards } from './Rewards';
import playButton from '../../../assets/waifus/playButton2.png'


export function CyberSkies() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        window.scrollTo(0, 0);
        // Start playing the video muted
        video.muted = true;
        video.play().catch(error => {
            // Autoplay was prevented
            console.log('Autoplay was prevented:', error);
        });
    }, []);

    const openVideo = () =>{
        window.open("https://youtu.be/I2K-rW7zia8", "_blank");
    } 


    return (
        <>
            <NavBar route="cyber-skies"/>

            <video ref={videoRef} className='cyberSkiesBackground' loop muted autoPlay >
                <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/cyberSkies.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <img src={line1} alt="line1" className="line1Skies" />
            <h2 className='mainHeaderSKIES'>CYBER SKIES</h2>
            <div className="group-containerSKIESMain">
                <div className="rectangle rectangle-1SKIES"></div>
                <div className="rectangle rectangle-2SKIES"></div>
            </div>
            <img src={playButton} alt='playButton' className='playButtonQuantum' onClick={openVideo}></img>

            <History/>
            <Character/>
            <Ships/>
            <Rewards/>
            <Footer height={"273.4vw"}/>
        </>
    )
};
