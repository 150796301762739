import React, { useEffect, useRef } from "react";
import { Coins, Globe, Gamepad, Users } from "lucide-react";
import { motion } from "framer-motion";
import "./LandingPage.css";
import cursedImage from "../assets/cursedMansion/background.png";
import skiesImage from "../assets/cyberSkies/bg.png";
import quantumImage from "../assets/waifus/background.png";
import cosmicImage from "../assets/cosmiclabs/duck.png";
import learnMore from "../assets/newHome/projs/learnMore.png";
import { NavBar } from "./NavBar";
const MotionFeatureCard = motion.div;
const MotionIPCard = motion.div;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};
const projectVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
      staggerChildren: 0.2,
    },
  },
};

const projectItemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const Web3Section = () => {
  const features = [
    {
      icon: <Coins className="feature-icon" size={32} />,
      title: "NFT Access Pass",
      subtitle: "Exclusive Membership",
      description:
        "Get premier access to our universe with the Genesis NFT pass. Enjoy exclusive benefits including:",
      benefits: [
        "Early access to all game releases",
        "Exclusive in-game items and cosmetics",
        "Priority access to events and meetups",
        "Voting rights on future development",
      ],
      className: "nft-feature",
    },
    {
      icon: <Globe className="feature-icon" size={32} />,
      title: "Native Token",
      subtitle: "Power The Ecosystem",
      description:
        "CHIMBA token is the foundation of our economic ecosystem, enabling:",
      benefits: [
        "Cross-game currency integration",
        "Staking rewards and governance",
        "NFT marketplace transactions",
        "Community reward distributions",
      ],
      className: "token-feature",
    },
    {
      icon: <Gamepad className="feature-icon" size={32} />,
      title: "Play & Earn",
      subtitle: "Game Economics",
      description:
        "Participate in our innovative gaming economy with features like:",
      benefits: [
        "In-game asset ownership",
        "Player-driven marketplaces",
        "Skill-based rewards",
        "Cross-game inventory system",
      ],
      className: "game-feature",
    },
  ];

  return (
    <motion.section
      className="web3-section"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
      variants={containerVariants}
    >
      <div className="web3-container">
        <motion.h2 className="web3-title" variants={itemVariants}>
          Web3 Conscious
        </motion.h2>
        <motion.p className="web3-subtitle" variants={itemVariants}>
          Experience the next generation of entertainment powered by blockchain
          technology
        </motion.p>

        <div className="features-grid">
          {features.map((feature, index) => (
            <MotionFeatureCard
              key={index}
              className={`feature-card ${feature.className}`}
              variants={itemVariants}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className="feature-header">
                <motion.div
                  className="icon-container"
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.6 }}
                >
                  {feature.icon}
                </motion.div>
                <div className="header-text">
                  <h3 className="feature-card-title">{feature.title}</h3>
                  <p className="feature-subtitle">{feature.subtitle}</p>
                </div>
              </div>

              <p className="feature-description">{feature.description}</p>

              <ul className="benefits-list">
                {feature.benefits.map((benefit, i) => (
                  <motion.li
                    key={i}
                    className="benefit-item"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <span className="benefit-bullet"></span>
                    {benefit}
                  </motion.li>
                ))}
              </ul>
            </MotionFeatureCard>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export function LandingPage() {
  const parallaxRef = useRef(null);
  const ipSectionRef = useRef(null);
  const backgroundRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (backgroundRef.current) {
        const scrolled = window.scrollY;
        backgroundRef.current.style.transform = `translateY(${
          scrolled * 0.5
        }px)`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleExploreClick = () => {
    ipSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const ips = [
    {
      title: "Cursed Mansion",
      description:
        "Unravel the mysteries within these haunted walls. A supernatural adventure awaits those brave enough to enter.",
      image: cursedImage,
      delay: "0s",
    },
    {
      title: "Cyber Skies",
      description:
        "Navigate through neon-drenched cityscapes and digital frontiers in this futuristic odyssey.",
      image: skiesImage,
      delay: "0.2s",
    },
    {
      title: "Quantum Breach",
      description:
        "Cross the boundaries of reality in a mind-bending journey through quantum dimensions.",
      image: quantumImage,
      delay: "0.4s",
    },
    {
      title: "Cosmic Labs",
      description:
        "Push the boundaries of science and exploration in this interstellar adventure.",
      image: cosmicImage,
      delay: "0.6s",
    },
  ];

  return (
    <main className="landing-page">
      <NavBar />
      <motion.section
        className="hero"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div ref={backgroundRef} className="hero-background" />
        <motion.div
          ref={parallaxRef}
          className="hero-content"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <motion.h1
            className="hero-title"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <span>Welcome to</span>
            Chimba Studios
          </motion.h1>
          <motion.p
            className="hero-description"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            We're building the next generation of Web3 Entertainment. Through
            original anime series, immersive games, and art-driven NFTs, we're
            bridging the gap between everyday people and the endless
            possibilities of Web3.
          </motion.p>
          <motion.div
            className="hero-buttons"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.8 }}
          >
            <motion.button
              className="cta-button exploreButton"
              ref={ipSectionRef}
              onClick={handleExploreClick}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Explore Our Universe
            </motion.button>
          </motion.div>
        </motion.div>
      </motion.section>

      <motion.div
        className="projects"
        variants={projectVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
        
      >
        {ips.map((ip, index) => (
          <motion.div
            key={index}
            className={`projectBG ${ip.title
              .toLowerCase()
              .replace(" ", "")}ProjBG`}
            variants={itemVariants}
            whileHover={{
              scale: 1.02,
              transition: { duration: 0.3 },
            }}
          >
            <img src={learnMore} className="learnMoreProjs"/>
          </motion.div>
        ))}
      </motion.div>

      <Web3Section />

      <motion.section
        className="cta-section"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <div className="cta-container">
          <motion.h2
            className="cta-title"
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Ready to Join the Adventure?
          </motion.h2>
          <motion.p
            className="cta-description"
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
          >
            Join our growing community of explorers, gamers, and creators. The
            future of entertainment awaits.
          </motion.p>
          <motion.div
            className="cta-buttons"
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6 }}
          >
            <motion.button
              className="cta-button socialButtons"
              onClick={() => {
                window.open(
                  "https://discord.com/invite/chimbastudios",
                  "_blank"
                );
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Join Discord
            </motion.button>
            <motion.button
              className="cta-button socialButtons"
              onClick={() => {
                window.open("https://x.com/Chimba_Studios", "_blank");
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Follow Twitter
            </motion.button>
          </motion.div>
        </div>
      </motion.section>
    </main>
  );
}
