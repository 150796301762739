import Lottie from 'react-lottie';

import clickAndHold from '../../assets/pass/clickAndHold.png';
import attention from '../../assets/pass/attention.json';
import accessAcademyTitle from '../../assets/pass/accessAcademyTitle.png';
import accessAcademyLine from '../../assets/pass/accessAcademyLine.png';
import Academy from '../../assets/pass/clickAndHold/Academy.png';
import { boxTriggered } from './passFunctions.js';
import { useRef, useState, useEffect} from 'react';


export function AccessAcademy({isClickAndHold ,setIsClickAndHold}) {

  const holdThreshold = 100;
  const holdTimerRef = useRef(null);

  const [brightness, setBrightness] = useState(1);
  
  const handleMouseDown = (divName) => {
    holdTimerRef.current = setTimeout(() => {
      let currentBrightness = 1;
      const interval = setInterval(() => {
        currentBrightness += 0.1;
        setBrightness(currentBrightness);

        if (currentBrightness >= 1.5) {
          setIsClickAndHold(true);
          boxTriggered(true, divName, "attention");
          clearInterval(interval);
        }
      }, 50);
    }, holdThreshold);
  };
  const handleMouseUp = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attention");
    setBrightness(1);
  };
  const handleMouseLeave = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attention");
    setBrightness(1);
  };

  useEffect(() => {
    // Apply brightness filter to #tvBox
    const tvBoxElement = document.getElementById('clickHoldTv');
    tvBoxElement.style.filter = `brightness(${brightness})`;

    // Clear the filter when the component unmounts
    return () => {
      tvBoxElement.style.filter = 'none';
    };
  }, [brightness]);

  return (
    <>
      <div id='tvVisiblity'>
        <div 
          id='tvBox'
          onMouseDown={()=>handleMouseDown("accessAcademyDiv")}
          onMouseUp={()=>handleMouseUp("accessAcademyDiv")}
          onMouseLeave={() => handleMouseLeave("accessAcademyDiv")}
        ></div>
        <img id='clickHoldTv' alt='clickHoldTv' src={clickAndHold}></img>
      </div>
      <div id='attention'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: attention,
          }}
          height={"10vw"}
          width={"7vw"}
        />
      </div>

      <div id='accessAcademyDiv'>
        <img src={accessAcademyTitle} alt='accessAcademyTitle' className='accessAcademyTitle'></img>
        <img src={accessAcademyLine} alt='accessAcademyLine' className='accessAcademyLine'></img>
        <p className='accessAcademyPara'>
          Holders of Chimba Pass enjoy
          lifetime access to Chimba Academy,
          unlocking an extensive library of
          cutting-edge digital education content.
        </p>
        <img src={Academy} alt='accessAcademyPH' className='accessAcademyPH'></img>
      </div>
    </>
  );
}
