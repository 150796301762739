import React, { useEffect } from 'react';
import { NavBar } from '../NavBar';

import './Css/about.css'

import line1 from '../../assets/about/line1.png'
import line2 from '../../assets/about/line2.png'
import line3 from '../../assets/about/line3.png'
import scrollToNav from '../../assets/about/scrollToNav.png'
import mouse from '../../assets/about/mouse.png'
import aboutLogo from '../../assets/about/aboutLogo.webp'
import aboutHeader from '../../assets/about/aboutHeader.png'
import chimbaBlock from '../../assets/about/chimbaBlock.png'
import learnMore from '../../assets/about/learnMore.png'
//Second section
import line4 from '../../assets/about/line4.png'
import line5 from '../../assets/about/line5.png'
import line6 from '../../assets/about/line6.png'
import firstHeader from '../../assets/about/mission.png'
import secondHeader from '../../assets/about/vision.png'
import goldChimba from '../../assets/about/goldChimba.png'
import zoe from '../../assets/about/zoe.webp'

import { Founder } from './Founder';
import Footer from '../Footer';

export function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
            <NavBar route="about"/>
            
            <img src={line1} alt="line1" className="line1A" />
            <img src={line2} alt="line2" className="line2A" />
            <img src={line3} alt="line3" className="line3A" />
            <img src={scrollToNav} alt="scrollToNav" className="scrollToNavA" />
            <img src={mouse} alt="mouse" className="mouseA" />
            <img src={aboutHeader} alt="aboutHeader" className="aboutHeader" />
            <img src={aboutLogo} alt="aboutLogo" className="aboutLogo" />
            
            <img src={chimbaBlock} alt="chimbaBlock" className="chimbaBlock" />


            <img src={learnMore} alt="learnMore" className="learnMore" onClick={()=>{window.open("https://x.com/Chimba_Studios", "_blank")}} />
            <p className='aboutPara'>
                Transforming gaming through unique IPs, empowerment, and a <br></br>
                global community in the Web 3.0 era. We're redefining entertainment.
            </p>

            <img src={line4} alt="line4" className="line4About" />
            <img src={line5} alt="line5" className="line5About" />
            <img src={line6} alt="line6" className="line6About" />

            <img src={firstHeader} alt="firstHeader" className="firstHeaderAbout" />
            <p className='firstHPAbout'>
                Revolutionizing gaming, inspiring with Chimba Games, enlightening through<br></br>
                Chimba Academy, and uplifting communities with our Foundation.
            </p>

            <img src={secondHeader} alt="secondHeader" className="secondHeaderAbout" />
            <p className='secondHPAbout'>
                Chimba Studios strives to be a leading Web3 entertainment brand,<br></br> 
                creating unrivaled experiences and growing one of the <br></br>largest global communities.
            </p>


            <img src={goldChimba} alt="goldChimba" className="goldChimba" />
            <img src={zoe} alt="zoe" className="zoe" />

            <Founder/>
            <Footer height={"186.4vw"}/>
        </>
    )
};