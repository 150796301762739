import React, { useEffect } from 'react';
import { NavBar } from '../NavBar';

import './Css/token.css'

import background from '../../assets/token/background.png'
import line1 from '../../assets/token/line1.png'
import line2 from '../../assets/token/line2.png'
import line3 from '../../assets/academy/line3.png'
import scrollToNav from '../../assets/academy/scrollToNav.png'
import mouse from '../../assets/academy/mouse.png'
import waitlist from '../../assets/token/waitlist.png'
import { Token2 } from './Token2';
import { Token3 } from './Token3';
import Footer from '../Footer';

export function Token() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return(
        <>
            <NavBar route="token"/>
            <img src={background} alt='background' className='tokenBackground'></img>
            <img src={line1} alt="line1" className="line1Token" />
            <img src={line2} alt="line2" className="line2Token" />
            <img src={line3} alt="line3" className="line3Token" />
            <img src={scrollToNav} alt="scrollToNav" className="scrollToNavToken" />
            <img src={mouse} alt="mouse" className="mouseToken" />

            <h2 className='tokenHeader'>TOKEN</h2>
            <div className="group-container">
                <div className="rectangle rectangle-1"></div>
                <div className="rectangle rectangle-2"></div>
            </div>

            <div className='tokenParaDiv'>
                <p className='tokenPara'>
                    <span className='tokenParaHeader'>GAMING FUEL</span><br></br>
                    The Chimba Token, a key pillar of Chimba Studios,
                    is a cryptocurrency crafted to empower our
                    game-changing approach. Designed with a strategic
                    tokenomics blueprint, it champions sustainable growth,
                    benefiting players, developers, and investors alike.
                </p>
            </div>

            {/* <img src={waitlist} alt='waitlist' className='waitlist'></img> */}
            <Token2/>
            <Token3/>
            <Footer height={"167vw"}/>
        </>
    )
};